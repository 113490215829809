<template>
  <div class="box">
    <el-empty description="无订单信息" :image-size="200"></el-empty>
  </div>
</template>

<script>
export default {
  name: "none-order",

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.box {
//   padding-top: 100px;
  position: relative;
  top: -119px;
  background: #fff;
  z-index: 9999;
  width: 1200px;
}
</style>